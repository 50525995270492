<template>
    <tr class="user-row"
        :class="{ 'user-row_inactive': !user.is_active }"
    >
        <td class="user-row__cell">
            {{user.login}}
        </td>
        <td class="user-row__cell">
            {{user.name}}
        </td>
        <td class="user-row__cell">
            {{user.right_name}}
        </td>
        <td class="user-row__cell">
            <template v-if="user.id_right === 1">Все отчеты и настройки</template>
            <template v-else-if="reportsAndSettingsList.length === 1">{{reportsAndSettingsList[0]}}</template>
            <popper
                v-else
                trigger="hover"
                :options="{
                    placement: 'left',
                    modifiers: { offset: { offset: '0,10px' } }
                }"
            >
                <ul class="popper user-row__tooltip">
                    <li v-for="item in reportsAndSettingsList" :key="item">{{item}}</li>
                </ul>
                <span slot="reference" class="user-row__cropped-text">{{reportsAndSettingsList.join(", ")}}</span>
            </popper>
        </td>
        <td class="user-row__cell">
            <template v-if="user.id_right === 1">Все рестораны</template>
            <template v-else-if="restaurantsList.length === 1">{{restaurantsList[0]}}</template>
            <popper
                v-else
                trigger="hover"
                :options="{
                    placement: 'left',
                    modifiers: { offset: { offset: '0,10px' } }
                }"
            >
                <ul class="popper user-row__tooltip">
                    <li v-for="item in restaurantsList" :key="item">{{item}}</li>
                </ul>
                <span slot="reference" class="user-row__cropped-text">{{restaurantsList.join(", ")}}</span>
            </popper>
        </td>
        <td class="user-row__cell user-row__cell_actions">
            <button class="user-row__action user-row__action_edit"
                @click="$emit('editUser', user.id)"
            >
                <span class="user-row__action-text">Изменить</span>
            </button>
            <button class="user-row__action "
                :class="`user-row__action_${user.is_active ? 'inactivate' : 'activate'}`"
                @click="$emit('toggleUserActivation', user.id)"
            >
                <template v-if="user.is_active">Деактивировать</template>
                <template v-else>Активировать</template>
            </button>
            <button class="user-row__action user-row__action_delete"
                v-if="user.id !== currentUser.id"
                @click="$emit('deleteUser', user.id)"
            >
                <span class="user-row__action-text">Удалить</span>
            </button>
        </td>
    </tr>
</template>

<script>
    export default {
        name: "UserRow",
        props: {
            user: {
                type: Object,
                required: true
            },
            currentUser: {
                type: Object,
                required: true
            }
        },
        computed: {
            restaurantsList() {
                return this.user.restaurants.engs.map(
                    restaurantEng => this.user.restaurants.byEng[restaurantEng].name
                );
            },
            reportsAndSettingsList() {
                let reports = this.user.methods.URLs.filter(
                    url => url.startsWith("report/")
                ).map(
                    url => this.user.methods.byURL[url].title
                );

                if (
                    ["get/income", "set/income"].every(url => this.user.methods.URLs.includes(url))
                ) {
                    reports.push("Настройка плана доходов");
                }

                if (
                    ["get/timing", "set/timing"].every(url => this.user.methods.URLs.includes(url))
                ) {
                    reports.push("Настройка тайминга");
                }

                return reports;
            }
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    .user-row {
        background-color: $background-gray-primary;

        &_inactive {
            color: $text-gray-secondary;
        }
    }
    .user-row__cell {
        padding: 16px;
        font-size: 16px;
        line-height: 1.5;

        &_actions {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
        }
    }
    .user-row__action {
        border: 1px solid;
        border-radius: 4px;
        padding: 5px 10px;
        font-size: 14px;
        text-align: center;

        & + & {
            margin-left: 10px;
        }

        &:hover {
            color: #fff;
        }

        &_edit {
            color: $blue-primary;

            &:hover {
                background-color: $blue-primary;
            }
        }

        &_activate {
            width: 120px;
            color: $green-primary;

            &:hover {
                background-color: $green-primary;
            }
        }

        &_inactivate {
            width: 120px;
            color: $red-primary;

            &:hover {
                background-color: $red-primary;
            }
        }

        &_delete {
            color: $red-primary;

            &:hover {
                background-color: $red-primary;
            }
        }
    }
    .user-row__cropped-text {
        display: block;
        overflow: hidden;
        width: 200px;
        white-space: nowrap;
        text-overflow: ellipsis;
        cursor: help;
        border-bottom: 1px dashed $text-gray-secondary;
    }
    .user-row__tooltip {
        padding: 5px 10px;
        text-align: left;
        background-color: #fff;
    }
</style>
