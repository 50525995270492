<template>
    <section class="settings-users">
        <spinner class="settings-users__spinner" v-if="status === 'loading'" />
        <error-message class="settings-users__error-message" v-else-if="status === 'error'">
            Не удалось загрузить настройки.
        </error-message>
        <template v-else>
            <header class="settings-users__header">
                <h4 class="settings-users__title h3">Список пользователей</h4>
                <button class="settings-users__action settings-users__action_add"
                    @click="showUserFormModal(null)"
                >
                    Добавить
                </button>
            </header>
            <div class="settings-users__box box">
                <table class="settings-users__table settings-users__table_striped">
                    <colgroup>
                        <col class="settings-users__table-column settings-users__table-column_login">
                        <col class="settings-users__table-column settings-users__table-column_name">
                        <col class="settings-users__table-column settings-users__table-column_right_name">
                        <col class="settings-users__table-column settings-users__table-column_restaurants">
                        <col class="settings-users__table-column settings-users__table-column_reports-settings">
                    </colgroup>
                    <thead>
                        <tr>
                            <th class="settings-users__table-cell settings-users__table-cell_head settings-users__table-cell_sortable"
                                scope="col"
                                @click="setSorting('login')"
                            >
                                <span class="settings-users__table-value">Логин</span>
                                <svg-icon class="settings-users__table-value settings-users__table-value_icon"
                                    v-if="sorting.field === 'login' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="settings-users__table-value settings-users__table-value_icon"
                                    v-if="sorting.field === 'login' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="settings-users__table-cell settings-users__table-cell_head settings-users__table-cell_sortable"
                                scope="col"
                                @click="setSorting('name')"
                            >
                                <span class="settings-users__table-value">Имя</span>
                                <svg-icon class="settings-users__table-value settings-users__table-value_icon"
                                    v-if="sorting.field === 'name' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="settings-users__table-value settings-users__table-value_icon"
                                    v-if="sorting.field === 'name' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="settings-users__table-cell settings-users__table-cell_head settings-users__table-cell_sortable"
                                scope="col"
                                @click="setSorting('right_name')"
                            >
                                <span class="settings-users__table-value">Роль</span>
                                <svg-icon class="settings-users__table-value settings-users__table-value_icon"
                                    v-if="sorting.field === 'right_name' && sorting.direction === 'ASC'"
                                    icon="chevron-up"
                                />
                                <svg-icon class="settings-users__table-value settings-users__table-value_icon"
                                    v-if="sorting.field === 'right_name' && sorting.direction === 'DESC'"
                                    icon="chevron-down"
                                />
                            </th>
                            <th class="settings-users__table-cell settings-users__table-cell_head" scope="col">Отчеты и настройки</th>
                            <th class="settings-users__table-cell settings-users__table-cell_head" scope="col">Рестораны</th>
                            <th class="settings-users__table-cell settings-users__table-cell_head" scope="col">Действия</th>
                        </tr>
                    </thead>
                    <tbody>
                        <user-row class="settings-users__table-row"
                            v-for="userItem in usersView"
                            :key="userItem.id"
                            :user="userItem"
                            :currentUser="user"
                            @deleteUser="showDeleteUserModal"
                            @toggleUserActivation="toggleUserActivation"
                            @editUser="showUserFormModal"
                        />
                    </tbody>
                </table>
            </div>
            <modal class="settings-users__modal"
                classes="settings-users__modal-content settings-users__modal-content_delete"
                :name="'deleteUserModal'"
                :min-width="300"
                :max-width="470"
                height="155px"
                :adaptive="true"
            >
                <template v-if="userToDelete">
                    <p class="settings-users__modal-heading h2">Удалить пользователя "{{userToDelete.name}}"?</p>
                    <p class="settings-users__modal-text">После выполнения это действие не может быть отменено.</p>
                    <div class="settings-users__actions settings-users__actions_modal">
                        <button class="settings-users__action settings-users__action_cancel"
                            @click="closeDeleteUserModal"
                        >
                            Отмена
                        </button>
                        <button class="settings-users__action settings-users__action_delete"
                            @click="deleteUser(userToDelete.id)"
                        >
                            Удалить
                        </button>
                    </div>
                </template>
            </modal>
            <modal class="settings-users__modal"
                classes="settings-users__modal-content settings-users__modal-content_form"
                :name="'userFormModal'"
                :min-width="300"
                :max-width="600"
                height="auto"
                :adaptive="true"
            >
                <form class="settings-users__user-form" @submit="saveUser">
                    <p class="settings-users__modal-heading h1">
                        <template v-if="userToEditId">Редактирование</template>
                        <template v-else>Добавление</template>
                        пользователя
                    </p>
                    <div class="settings-users__controls">
                        <div class="settings-users__control">
                            <label class="settings-users__control-label h3" for="login">Логин</label>
                            <input class="settings-users__control-input"
                                type="text"
                                id="login"
                                :class="{ 'settings-users__control-input_invalid': userFormData.login.isInvalid }"
                                :value="userFormData.login.value"
                                @input="handleUserFormInput('login', $event)"
                            >
                            <span class="settings-users__control-error" v-if="userFormData.login.error">{{userFormData.login.error}}</span>
                        </div>
                        <div class="settings-users__control">
                            <label class="settings-users__control-label h3" for="name">Имя</label>
                            <input class="settings-users__control-input"
                                type="text"
                                id="name"
                                autocomplete="username"
                                :class="{ 'settings-users__control-input_invalid': userFormData.name.isInvalid }"
                                :value="userFormData.name.value"
                                @input="handleUserFormInput('name', $event)"
                            >
                            <span class="settings-users__control-error" v-if="userFormData.name.error">{{userFormData.name.error}}</span>
                        </div>
                        <div class="settings-users__control settings-users__control_password settings-users__control_full-width">
                            <label class="settings-users__control-label h3" for="password">Пароль</label>
                            <input class="settings-users__control-input"
                                type="password"
                                id="password"
                                autocomplete="new-password"
                                :class="{ 'settings-users__control-input_invalid': userFormData.password.isInvalid }"
                                :value="userFormData.password.value"
                                @input="handleUserFormInput('password', $event)"
                            >
                            <span class="settings-users__control-error" v-if="userFormData.password.error">{{userFormData.password.error}}</span>
                        </div>
                        <div class="settings-users__control settings-users__control_role settings-users__control_full-width">
                            <label class="settings-users__control-label h3" for="id_right">Роль</label>
                            <select class="settings-users__control-select"
                                v-model="userFormData.id_right"
                                id="id_right"
                            >
                                <option v-for="right in rights"
                                    :value="right.id_right"
                                    :key="right.id_right"
                                >
                                    {{right.right_name}}
                                </option>
                            </select>
                        </div>
                        <div class="settings-users__control settings-users__control_restaurants settings-users__control_full-width"
                            v-if="userFormData.id_right !== 1"
                        >
                            <p class="settings-users__control-label h3" for="id_right">Права на рестораны</p>
                            <multiselect
                                v-model="userFormData.restaurants"
                                :options="restaurantsView"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Выберите рестораны"
                                label="name"
                                track-by="eng"
                                :preselect-first="false"
                                :showLabels="false"
                            >
                                <template
                                    slot="selection"
                                    slot-scope="{ values, isOpen }"
                                >
                                    <span class="multiselect__single" v-if="values.length && !isOpen">
                                        Выбрано: {{values.length}}
                                    </span>
                                </template>
                            </multiselect>
                        </div>
                        <div class="settings-users__control settings-users__control_reports settings-users__control_full-width"
                            v-if="userFormData.id_right !== 1"
                        >
                            <p class="settings-users__control-label h3" for="id_right">Права на отчеты и настройки</p>
                            <multiselect
                                v-model="userFormData.methods"
                                :options="methods"
                                :multiple="true"
                                :close-on-select="false"
                                :clear-on-select="false"
                                :preserve-search="true"
                                placeholder="Выберите отчеты и настройки"
                                label="title"
                                track-by="id"
                                :preselect-first="false"
                                :showLabels="false"
                            >
                                <template
                                    slot="selection"
                                    slot-scope="{ values, isOpen }"
                                >
                                    <span class="multiselect__single" v-if="values.length && !isOpen">
                                        Выбрано: {{values.length}}
                                    </span>
                                </template>
                            </multiselect>
                        </div>
                    </div>
                    <div class="settings-users__actions settings-users__actions_modal">
                        <button class="settings-users__action settings-users__action_cancel"
                            type="reset"
                            @click="closeUserFormModal"
                        >
                            Отмена
                        </button>
                        <button class="settings-users__action settings-users__action_delete"
                            type="submit"
                            :disabled="isUserFormSubmitDisabled"
                        >
                            Сохранить
                        </button>
                    </div>
                </form>
            </modal>
        </template>
    </section>
</template>

<script>
    import { mapState, mapGetters, mapActions } from "vuex";
    import { compareStrings, compareNumbers } from "@/helpers/compare";
    import UserRow from "@/components/UserRow";

    export default {
        name: "SettingsUsers",
        components: {
            UserRow
        },
        data() {
            return {
                status: "loading",
                sorting: {
                    field: "right_name",
                    direction: "ASC"
                },
                userToDeleteId: null,
                userToEditId: null,
                userFormData: {
                    login: {
                        value: null,
                        isInvalid: false,
                        error: null
                    },
                    name: {
                        value: null,
                        isInvalid: false,
                        error: null
                    },
                    password: {
                        value: null,
                        isInvalid: false,
                        error: null
                    },
                    restaurants: [],
                    methods: [],
                    id_right: 3
                },
            };
        },
        computed: {
            ...mapState({
                users: state => state.directories.users,
                restaurants: state => state.directories.restaurants,
                rights: state => state.directories.rights,
                methods: state => state.directories.methods
            }),
            ...mapGetters([
                "areDirectoriesLoaded",
                "user"
            ]),
            usersView() {
                if (this?.users?.ids) {
                    return Object.values(this?.users?.byId)?.sort((user1, user2) => {
                        let compare;

                        switch (this.sorting.field) {
                            case "login":
                            case "name":
                            case "right_name":
                                compare = compareStrings;
                                break;
                            default:
                                compare = compareNumbers;
                                break;
                        };

                        return compare(user1[this.sorting.field], user2[this.sorting.field], this.sorting.direction);
                    });
                }
            },
            restaurantsView() {
                if (this?.restaurants?.engs) {
                    return Object.values(this?.restaurants?.byEng);
                }
            },
            userToDelete() {
                return this.users?.byId?.[this.userToDeleteId];
            },
            userToEdit() {
                return this.users?.byId?.[this.userToEditId];
            },
            isUserFormSubmitDisabled() {
                if (
                    this.userFormData.id_right !== 1 &&
                    (this.userFormData.restaurants.length === 0 || this.userFormData.methods.length === 0)
                ) {
                    return true;
                }

                if (this.userToEdit) {
                    return this.userFormData.login.isInvalid ||
                        this.userFormData.name.isInvalid ||
                        (this.userFormData.password.value && this.userFormData.password.isInvalid);
                } else {
                    for (const parameter of ["login", "name", "password"]) {
                        if (!this.userFormData[parameter].value || this.userFormData[parameter].isInvalid) {
                            return true;
                        }
                    }
                }
            }
        },
        methods: {
            ...mapActions([
                "getDirectoriesFromDB",
                "createMessage",
                "deleteUserFromDB",
                "setUserAcivationInDB",
                "updateUserInDB",
                "addUserToDB"
            ]),
            showData() {
                this.status = "success";
            },
            showError() {
                this.status = "error";
            },
            loadUsers() {
                this.getDirectoriesFromDB().then(this.showData, this.showError);
            },
            setSorting(field) {
                if (this.sorting.field === field) {
                    this.sorting.direction = this.sorting.direction === "ASC" ? "DESC" : "ASC";
                } else {
                    this.sorting.field = field
                }
            },
            showDeleteUserModal(id) {
                this.userToDeleteId = id;
                this.$modal.show("deleteUserModal");
            },
            closeDeleteUserModal() {
                this.$modal.hide("deleteUserModal");
                this.userToDeleteId = null;
            },
            deleteUser(id) {
                let user = this.users.byId[id];

                this.$modal.hide("deleteUserModal");

                this.deleteUserFromDB(user.id).then(() => {
                    this.createMessage({
                        type: "success",
                        text: `Пользователь "${user.name}" был удалён`
                    });
                }).catch(() => {
                    this.createMessage({
                        type: "failure",
                        text: `Не удалось удалить пользователя "${user.name}"`
                    });
                }).finally(() => {
                    this.userToDeleteId = null;
                });
            },
            showUserFormModal(id) {
                if (id) {
                    this.userToEditId = id;
                    this.userFormData.login.value = this.userToEdit.login;
                    this.userFormData.name.value = this.userToEdit.name;
                    this.userFormData.id_right = this.userToEdit.id_right;
                    this.userFormData.restaurants = Object.values(this.userToEdit.restaurants.byEng);
                    this.userFormData.methods = Object.values(this.userToEdit.methods.byURL);
                } else {
                    this.userToEditId = null;
                    this.userFormData.login.value = null;
                    this.userFormData.name.value = null;
                    this.userFormData.id_right = 3;
                    this.userFormData.restaurants = [];
                    this.userFormData.methods = [];
                }

                this.userFormData.password.value = null;

                for (const parameter of ["login", "name", "password"]) {
                    this.userFormData[parameter].isInvalid = false;
                    this.userFormData[parameter].error = null;
                }

                this.$modal.show("userFormModal");
            },
            closeUserFormModal() {
                this.$modal.hide("userFormModal");
            },
            handleUserFormInput(parameter, { target: { value }}) {
                this.userFormData[parameter].value = value;

                if (parameter === "login") {
                    let logins = Object.values(this.users.byId).map(user => user.login).filter(login => {
                        if (this.userToEdit) {
                            return login !== this.userToEdit.login;
                        }

                        return true;
                    });

                    if (value === "") {
                        this.userFormData.login.isInvalid = true;
                        this.userFormData.login.error = "Логин должен быть заполнен";
                    } else if (logins.includes(value)) {
                        this.userFormData.login.isInvalid = true;
                        this.userFormData.login.error = "Логин занят";
                    } else {
                        this.userFormData.login.isInvalid = false;
                        this.userFormData.login.error = null;
                    }

                    return;
                }

                if (parameter === "name") {
                    if (value === "") {
                        this.userFormData.name.isInvalid = true;
                        this.userFormData.name.error = "Имя должно быть заполнено";
                    } else {
                        this.userFormData.name.isInvalid = false;
                        this.userFormData.name.error = null;
                    }

                    return;
                }

                if (parameter === "password") {
                    if (value === "") {
                        if (!this.userToEdit) {
                            this.userFormData.password.isInvalid = true;
                            this.userFormData.password.error = "Пароль должен быть заполнен";
                        } else {
                            this.userFormData.password.isInvalid = false;
                            this.userFormData.password.error = null;
                        }
                    } else if (value.length < 6 || !(/\d/).test(value)) {
                        this.userFormData.password.isInvalid = true;
                        this.userFormData.password.error = "Минимум 6 символов, должен содержать цифры";
                    } else {
                        this.userFormData.password.isInvalid = false;
                        this.userFormData.password.error = null;
                    }

                    return;
                }
            },
            saveUser(event) {
                event.preventDefault();

                if (this.userToEdit) {
                    let updates = {};

                    if (this.userFormData.login.value && !this.userFormData.login.isInvalid) {
                        updates.login = this.userFormData.login.value;
                    }

                    if (this.userFormData.name.value && !this.userFormData.name.isInvalid) {
                        updates.name = this.userFormData.name.value;
                    }

                    if (this.userFormData.id_right !== this.userToEdit.id_right) {
                        let right = this.rights.find(({ id_right }) => id_right === this.userFormData.id_right);
                        updates = {
                            ...updates,
                            ...right
                        };
                    }

                    if (this.userFormData.password.value && !this.userFormData.password.isInvalid) {
                        updates.password = this.userFormData.password.value;
                    }

                    if (this.userFormData.id_right == 1) {
                        updates.restaurants = this.userFormData.restaurants.map(({ eng }) => eng);
                        updates.methods = this.methods.map(({ id }) => id);
                    } else {
                        if (this.userFormData.restaurants.length > 0) {
                            updates.restaurants = this.userFormData.restaurants.map(({ eng }) => eng);
                        }

                        if (this.userFormData.methods.length > 0) {
                            updates.methods = this.userFormData.methods.map(({ id }) => id);
                        }
                    }

                    this.updateUserInDB({
                        id: this.userToEdit.id,
                        updates
                    }).then(() => {
                        this.createMessage({
                            type: "success",
                            text: `Изменения сохранены`
                        });
                    }).catch(() => {
                        this.createMessage({
                            type: "failure",
                            text: `Не удалось сохранить изменения`
                        });
                    }).finally(() => {
                        this.userToEditId = null;
                    });
                } else {
                    let userData = {
                        login: this.userFormData.login.value,
                        name: this.userFormData.name.value,
                        password: this.userFormData.password.value,
                        id_right: this.userFormData.id_right,
                        restaurants: this.userFormData.restaurants.map(({ eng }) => eng),
                        methods: this.userFormData.methods.map(({ id }) => id)
                    };

                    if (this.userFormData.id_right == 1) {
                        userData.restaurants = this.restaurants.engs;
                        userData.methods = this.methods.map(({ id }) => id);
                    }

                    this.addUserToDB(userData).then(() => {
                        this.createMessage({
                            type: "success",
                            text: `Пользователь "${this.userFormData.name.value}" создан"`
                        });
                    }).catch(() => {
                        this.createMessage({
                            type: "failure",
                            text: `Не удалось создать пользователя "${this.userFormData.name.value}"`
                        });
                    });
                }

                this.closeUserFormModal();
            },
            toggleUserActivation(id) {
                this.userToEditId = id;

                this.updateUserInDB({
                    id: this.userToEdit.id,
                    updates: {
                        is_active: !this.userToEdit.is_active
                    }
                 }).then((user) => {
                    this.createMessage({
                        type: "success",
                        text: `Пользователь "${user.name}" ${user.is_active ? "активирован" : "деактивирован"}`
                    });
                }).catch((user) => {
                    this.createMessage({
                        type: "failure",
                        text: `Не удалось ${user.is_active ? "деактивировать" : "активировать"} пользователя "${user.name}"`
                    });
                }).finally(() => {
                    this.userToEditId = null;
                });
            },
        },
        created() {
            if (this.areDirectoriesLoaded) {
                this.showData();
            } else {
                this.loadUsers();
            }
        }
    }
</script>

<style lang="scss">
    .settings-users__header {
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: flex-end;
        margin-bottom: 20px;
    }
    .settings-users__title {
        line-height: 1;
    }
    .settings-users__box {
        overflow-x: auto;
    }
    .settings-users__table {
        width: 100%;
    }
    .settings-users__table-caption {
        padding: 8px 8px 0;
        font-size: 24px;
        font-weight: bold;
    }
    .settings-users__table-column {
        &_name {
            width: 15%;
        }
    }
    .settings-users__table-row {
        border-bottom: 1px solid $gray-line;
    }
    .settings-users__table-cell {
        font-size: 20px;

        &_head {
            padding: 17px;
            white-space: nowrap;
        }

        &_sortable {
            cursor: pointer;
        }

        &_title {
            font-weight: bold;
        }
    }
    .settings-users__table-value {
        vertical-align: middle;

        & + & {
            margin-left: 5px;
        }

        &_icon {
            width: 24px;
            height: 24px;
        }
    }
    .settings-users__modal-content {
        padding: 20px;
        overflow: visible;

        &_delete {
            text-align: center;
        }
    }
    .settings-users__modal-heading {
        margin-bottom: 10px;
    }
    .settings-users__actions {
        margin-top: 20px;

        &_form {
            text-align: right;
        }
    }
    .settings-users__action {
        padding: 10px 15px;
        border: 1px solid;
        border-radius: 4px;

        & + & {
            margin-left: 20px;
        }

        &_cancel {
            border-color: $gray-line;
        }

        &_add {
            width: 180px;
            color: #fff;
            border-color: $green-primary;
            background-color: $green-primary;
        }

        &_delete {
            color: #fff;
            border-color: $red-primary;
            background-color: $red-primary;
        }

        &[disabled] {
            cursor: not-allowed;
            border-color: $gray-line;
            background-color: $gray-line;
        }
    }
    .settings-users__controls {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-auto-flow: row;
        grid-gap: 4px 10px;
    }
    .settings-users__control {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: flex-start;
        padding-bottom: 16px;
        position: relative;

        &_full-width {
            grid-column: 1/-1;
        }

        & .multiselect__tags-wrap {
            display: none !important;
        }
    }
    .settings-users__control-label {
        margin: 0 0 5px;
    }
    .settings-users__control-input {
        width: 100%;
        padding: 8px 10px;
        border: 1px solid $gray-line;
        border-radius: 4px;

        font-size: 16px;
        line-height: 1;

        &[disabled] {
            background-color: $gray-line;
        }

        @include desktop {
            font-size: 20px;
        }

        &:focus {
            outline: none;
        }

        &_invalid {
            border-color: $red-secondary;
        }
    }
    .settings-users__control-error {
        color: $red-secondary;
        font-size: 14px;
        line-height: 1;
        position: absolute;
        left: 0;
        bottom: 0;
    }
    .settings-users__control-select {
        width: 100%;
        height: 42px;
        padding: 5px;
        border: 1px solid $gray-line;
        border-radius: 4px;
        cursor: pointer;

        @include desktop {
            font-size: 20px;
        }

        &:focus {
            outline: none;
        }
    }
</style>
